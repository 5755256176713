export function convertWeightMeasurements(unitMeasurement, weight) {
  let result;
  switch (unitMeasurement) {
    case "kgs":
      result = weight * 2.20462;
      break;
    case "ton":
      result = weight * 2204.62;
      break;
    default:
      result = weight;
      break;
  }
  return Math.ceil(result);
}

export function convertLengthMeasurements(unitMeasurement, length) {
  let result;
  switch (unitMeasurement) {
    case "ft":
      result = length * 12;
      break;
    case "cms":
      result = length * 0.393701;
      break;
    case "mts":
      result = length * 39.3701;
      break;
    default:
      result = length;
      break;
  }
  return Math.ceil(result);
}
