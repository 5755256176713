<template>
  <div class="modal-excluded-commodities">
    <p class="modal-excluded__text">We do not move the following commodities</p>
    <ul class="custom-modal-content">
      <li class="custom-modal-item" v-for="item in listOfExcluded" :key="item.title">
        <div
          class="container-list"
          v-if="quoteMode === 'all-options' || item.mode === quoteMode || !quoteMode"
        >
          <h6>{{ item.title }}:</h6>
          <ul class="list-menu">
            <li class="custom-model-subitem" v-for="(subItem, index) in item.list" :key="index">
              - {{ subItem }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ModalExcludedCommodities",
  props: {
    quoteMode: String,
  },
  data() {
    return {
      listOfExcluded: [
        {
          title: "LTL",
          mode: "ltl",
          list: [
            "Living creatures / living things / living beings.",
            "Nitrocellulose.",
            "Organic Fluids.",
            "Fuel.",
            "Anything that is dead (or ashes).",
            "Cars, ATVs, Golf Carts, Small Tractors, etc.",
            "Cannabis or its derivatives.",
            "Oil, (We do move its derivatives).",
            "Loose tires (rims), (they must be on pallets or boxes).",
            "Loose pallets, drums, or cylinders, (they must be on pallets or crates).",
            "Parts with wheels unpackaged (they must be on pallets or boxes).",
            "Perishables.",
            "Gemstones, checks or bills with commercial value. Jewelry of high commercial value.",
          ],
        },
        {
          title: "FTL & Flatbed",
          mode: "ftl",
          list: [
            "Ammunition.",
            "Live Animals.",
            // eslint-disable-next-line max-len
            "If the shipment is considered HAZMAT, please note we do not haul the following classes: (1.1)(1.2)(1.3)(1.4)(1.5)(1.6)(2.3)(6.1)(6.2)( 7). Class 2.3 A-B-C-D.",
            "Toxic Class 6.1 (A,B,C,D) 6.2 CAT A,6.2 CAT B, Class 7White 1 - Yellow 2 Yellow 3.",
            "Perishable food.",
          ],
        },
        {
          title: "Expedited",
          mode: "expedited",
          list: [
            "Nitrocellulose.",
            "Hazmat goods greater than 1000 lbs.",
            "Alcohol (If it needs a license to be transported).",
            "Cigarettes, vapes, e-cigars.",
            "Artworks.",
            "Explosives.",
            "Infectious substances.",
            "Hospital waste.",
            "Firearms and ammunition together (this also depend weather it needs a license).",
            "Live Animals.",
          ],
        },
        {
          title: "Reefer FTL",
          mode: "reefer",
          list: [
            "Blood related commodities.",
            "Organs.",
            "Animals.",
            " Live human beings  Hazmat (1.1)(1.2)(1.3)(1.4)(1.5)(1.6)(2.3)(6.1)(6.2)(7)",
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-excluded {
  &__text {
    color: $color-primary-company;
    font-size: 18px;
    font-family: $font-family-portal;
    margin: 0px;
  }
}
.custom-modal-item {
  color: $color-primary-company;
  font-size: 14px;
  font-style: italic;
  font-family: $font-family-portal;
  margin: 10px auto;
  list-style: none;
}
.custom-modal-content {
  margin-bottom: 8px;
  padding: 8px 15px;
}
.custom-model-subitem {
  list-style: none;
}
.list-menu {
  padding-left: 15px;
}
h6 {
  font-weight: bold;
  font-style: normal;
}
</style>
