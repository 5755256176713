export const convertDimensionsMeasure = (unit, value) => {
  let result;
  switch (unit) {
    case "mts":
      result = value * 0.0254;
      break;
    case "cms":
      result = value * 2.5;
      break;
    case "ft":
      result = value * 0.0833333;
      break;
    default:
      result = value;
      break;
  }
  return result.toFixed(2);
};

export const convertWeightMeasure = (unit, value) => {
  let result;
  switch (unit) {
    case "kgs":
      result = value * 0.453592;
      break;
    case "ton":
      result = value * 0.0005;
      break;
    default:
      result = value;
      break;
  }
  return result.toFixed(0);
};
