import { mapGetters } from "vuex";
import { convertWeightMeasurements } from "@/utils/measurementConverter";
import DexieDb from "@/utils/db";

export default {
  data() {
    return {
      loading: false,
      key: 0,
      picklist: {},
      remountLineItems: 0,
      showDeleteButton: false,
      selectedLineItems: null,
      error: [],
      requiredAllFields: false,
      commoditySuggestions: [],
    };
  },
  computed: {
    ...mapGetters({
      chosenAccessorials: "load/accessorials/getChosenAccessorials",
    }),
    handlingUnitsList() {
      return this.picklist.handling_units.map((item) => item.value);
    },
  },
  watch: {
    selectedLineItems: {
      deep: true,
      handler() {
        this.showDeleteButton = this.selectedLineItems.length > 1;
        /* This emit is created to be used in NewLineItemModal, which is an instance for
        creating line items per account in settings. */
        this.$emit("input", this.selectedLineItems);
      },
    },
    isHazmat() {
      if (!this.isHazmat) {
        // this.removeHazmatInformation();
      }
    },
  },
  async created() {
    this.loading = true;
    this.commoditySuggestions = JSON.parse(localStorage.getItem("commodities")) || [];
    const { loadId } = this.$route.params;
    this.selectedLineItems = this.$deepCopy(this.lineItems);
    if (loadId) {
      await this.checkLoadIdInIndexedDB();
      this.requiredAllFields = true;
    }
    await this.getPickListItem();
    this.saveSelectedLineItemInVuex();
    this.loading = false;
  },
  methods: {
    updateLineItemsInIndexedDB() {
      DexieDb.updateDb({
        collection: "lineItems",
        data: { loadId: this.$route.params.loadId, items: [...this.selectedLineItems] },
      });
    },
    async checkLoadIdInIndexedDB() {
      const lineItems = await DexieDb.getFromDb({
        dbName: "quoteBook",
        collection: "lineItems",
        id: this.$route.params.loadId,
      });
      if (!lineItems) {
        const completeLineItems = this.checkIfThereAreUserVariables(this.lineItems);
        this.selectedLineItems = [...completeLineItems];
        DexieDb.addToDb({
          collection: "lineItems",
          data: { loadId: this.$route.params.loadId, items: [...this.selectedLineItems] },
        });
        return;
      }
      this.selectedLineItems = [...lineItems.items];
    },
    deleteIndexedDBInfo() {
      DexieDb.deleteFromDb({
        collection: "lineItems",
        id: this.$route.params.loadId,
      });
    },
    // hazmat functions
    setHazmatToFirstLineItem(item) {
      if (!item.hazardous_materials) {
        item.hazardous_materials = true;
        item.hazmat_contact = process.env.VUE_APP_HAZMAT_ID_DEV;
      }
    },
    hasHazmatAccessorial() {
      return this.chosenAccessorials.some(
        (accessorial) => accessorial.name.toLowerCase() === "hazmat"
      );
    },
    // weigth conversor
    weightConverter(index) {
      this.selectedLineItems[index].weight = parseFloat(
        convertWeightMeasurements(
          this.selectedLineItems[index].user_weight_units,
          this.selectedLineItems[index].user_weight
        ),
        10
      ).toFixed(2);
    },
    // lineItems
    async deleteLine(index) {
      this.$store.commit("load/setUpdatingRequestInProcess", true);
      this.$store.commit("load/lineItems/deleteLineItem", index);
      this.selectedLineItems.splice(index, 1);
      if (this.$route.params.loadId) {
        this.updateLineItemsInIndexedDB();
      }
      this.$store.commit("load/setUpdatingRequestInProcess", false);
      this.remountLineItems += 1;
    },
    saveSelectedLineItemInVuex() {
      const selectedLineItems = this.$deepCopy(this.selectedLineItems);
      this.$store.commit("load/lineItems/setSelectedLineItems", selectedLineItems);
    },
    // List of requests for items to be updated or created (edit load)
    updateLineItemsToLoad() {
      const lineItemsRequests = [];
      const chosenLoad = this.$store.getters["load/getChosenLoad"];
      this.selectedLineItems.forEach((item, index) => {
        // This line is to assign hazmat only to the first item.
        if (index === 0 && this.hasHazmatAccessorial()) {
          this.setHazmatToFirstLineItem(item);
        }
        // If the line item have id update line item to related load
        if (item.line_item_id) {
          const payload = {
            id: this.$route.params.loadId,
            lineItemId: item.line_item_id,
            body: item,
          };
          this.removeUnusedProperties(item);
          const itemValidation = this.validateItemsForUpdates(
            chosenLoad.line_items,
            this.selectedLineItems[index]
          );
          if (itemValidation) {
            if (this.$options.filters.checkPermission("patch:line-item")) {
              lineItemsRequests.push(
                this.$store.dispatch("load/lineItems/updatedLineItem", payload)
              );
            }
          }
        } else {
          // If the line item have not id create line item to related load
          item.load_id = this.selectedLineItems[0].load_id;
          item.delivery_stop = chosenLoad.stops.find((stop) => stop.is_dropoff).id;
          item.pickup_stop = chosenLoad.stops.find((stop) => stop.is_pickup).id;
          // item.hazardous_materials = this.selectedLineItems[0].hazardous_materials;
          const payload = {
            id: this.$route.params.loadId,
            body: item,
          };
          if (this.$options.filters.checkPermission("post:line-item")) {
            lineItemsRequests.push(this.$store.dispatch("load/lineItems/createLineItem", payload));
          }
        }
      });
      return lineItemsRequests;
    },
    // List of requests for items to be eliminated (edit load)
    deleteLineItemsRequests() {
      if (this.$options.filters.checkPermission("delete:line-item")) {
        const chosenLoadLineItems = this.$store.getters["load/getChosenLoad"].line_items;
        const deletedLineItems = chosenLoadLineItems.filter(
          /* eslint-disable implicit-arrow-linebreak */
          (item) =>
            !this.selectedLineItems.find((lineItem) => lineItem.line_item_id === item.line_item_id)
        );
        const requests = [];
        deletedLineItems.forEach((item) => {
          const payload = {
            load_id: item.load_id,
            id: item.line_item_id,
          };
          requests.push(this.$store.dispatch("load/lineItems/deleteLineItem", payload));
        });
        return requests;
      }
      return null;
    },
    /*
      validate if the chosen load and selected line items
      have changes and validate if is necessary dispatch the function to update.
    */
    validateItemsForUpdates(chosenLoadLineItems, item) {
      const foundItem = chosenLoadLineItems.find(
        (lineItem) => lineItem.line_item_id === item.line_item_id
      );
      let itemWasModified = false;
      if (foundItem) {
        const keys = Object.keys(item);
        keys.forEach((key) => {
          // eslint-disable-next-line eqeqeq
          if (item[key] != foundItem[key]) {
            itemWasModified = true;
          }
          return null;
        });
      }
      return itemWasModified;
    },
    removeUnusedProperties(item) {
      delete item.density;
      delete item.linear_feet;
      delete item.nmfc_number;
    },
    checkIfThereAreUserVariables(lineItems) {
      const lineItemsCopy = lineItems.map((element) => {
        const elementCopy = { ...element };
        if (!elementCopy.user_weight) {
          elementCopy.user_weight = elementCopy.weight;
        }
        if (!elementCopy.user_weight_units) {
          elementCopy.user_weight_units = elementCopy.weight_units;
        }
        if (!elementCopy.user_length) {
          elementCopy.user_length = elementCopy.length;
        }
        if (!elementCopy.user_width) {
          elementCopy.user_width = elementCopy.width;
        }
        if (!elementCopy.user_height) {
          elementCopy.user_height = elementCopy.height;
        }
        if (!elementCopy.user_dimension_units) {
          elementCopy.user_dimension_units = elementCopy.dimension_units;
        }
        return elementCopy;
      });
      return lineItemsCopy;
    },
    // needed data to line items
    async getPickListItem() {
      if (this.$options.filters.checkPermission("get:line-item-picklists")) {
        const response = await this.$store.dispatch("load/lineItems/getLineItemPickList");
        Object.keys(response).forEach((key) => {
          this.picklist[key] = response[key].map((el) => ({ label: el.label, value: el.value }));
          if (key === "weight_units") {
            this.picklist.weight_units.push(
              { label: "kgs", value: "kgs" },
              { label: "ton", value: "ton" }
            );
          }
          if (key === "dimension_units") {
            this.picklist.dimension_units.push(
              { label: "cms", value: "cms" },
              { label: "mts", value: "mts" }
            );
          }
        });
      }
    },
  },
};
